/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useRef, useMemo, useEffect } from "react";
import { Page, InlineStack, Icon, Popover, Button, Card, Form, FormLayout, TextField, Select, DatePicker } from "@shopify/polaris";
import { CalendarIcon, EmailIcon, PhoneIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";

import { useGiftReggieFetch } from "../../components/LegacyContainer";
import LegacyMessageContext from "../../components/LegacyMessageContext";

function CreateList() {
  const fetchGr = useGiftReggieFetch();
  const [loaded, setLoaded] = useState(false);

  const REGISTRY_URL = "/reggie/registries";
  const DAYS = 86400000; // number of milliseconds in a day

  const todayDate = useMemo(() => new Date(), []);
  const yesterdayDate = new Date(todayDate - 1 * DAYS);
  const [registryTypes, setRegistryTypes] = useState([]);

  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [registrantFirst, setRegistrantFirst] = useState(null);
  const [registrantLast, setRegistrantLast] = useState(null);
  const [coregistrantFirst, setCoregistrantFirst] = useState(null);
  const [coregistrantLast, setCoregistrantLast] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);
  const [contactAddress, setContactAddress] = useState(null);
  const [contactCity, setContactCity] = useState(null);
  const [contactProvince, setContactProvince] = useState(null);
  const [contactCountry, setContactCountry] = useState(null);
  const [contactPostal, setContactPostal] = useState(null);
  const [contactPhone, setContactPhone] = useState(null);

  const [visible, setVisible] = useState(false);
  const [{ monthDate, yearDate }, setDate] = useState({
    monthDate: todayDate.getMonth(),
    yearDate: todayDate.getFullYear(),
  });
  const [selectedDate, setselectedDate] = useState(todayDate);
  const formattedValue = selectedDate.toISOString().slice(0, 10);
  const handleMonthChangeGR = useCallback((monthDate, yearDate) => setDate({ monthDate, yearDate }), []);
  const datePickerRef = useRef(null);
  const handleOnClose = useCallback(() => {
    setVisible(false);
  }, []);
  const handleDateSelection = useCallback((newSelectedDate) => {
    setselectedDate(newSelectedDate.start);
    setVisible(false);
  }, []);

  const discard = useCallback(() => {
    setName("");
    setType(registryTypes[0]);
    setRegistrantFirst("");
    setRegistrantLast("");
    setCoregistrantFirst("");
    setCoregistrantLast("");
    setContactEmail("");
    setContactAddress("");
    setContactCity("");
    setContactProvince("");
    setContactCountry("");
    setContactPostal("");
    setContactPhone("");
    setselectedDate(todayDate);
    setDate({
      monthDate: todayDate.getMonth(),
      yearDate: todayDate.getFullYear(),
    });
  }, [registryTypes, todayDate]);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData();

      formData.append("registry-name", name);
      formData.append("registry-type", type);
      formData.append("registry-registrant-first-name", registrantFirst);
      formData.append("registry-registrant-last-name", registrantLast);
      formData.append("registry-coregistrant-first-name", coregistrantFirst);
      formData.append("registry-coregistrant-last-name", coregistrantLast);
      formData.append("registry-contact-email", contactEmail);
      formData.append("registry-contact-address", contactAddress);
      formData.append("registry-contact-city", contactCity);
      formData.append("registry-contact-province", contactProvince);
      formData.append("registry-contact-country", contactCountry);
      formData.append("registry-contact-postal", contactPostal);
      formData.append("registry-contact-phone", contactPhone);
      formData.append("registry-date", `${selectedDate.getMonth() + 1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`);

      fetchGr(REGISTRY_URL, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      })
        .then((r) => {
          console.log(r);
          if (r && r.ACTIVE_SUCCESS) {
            const createdId = r.REGISTRIES[0].ID;
            window.shopify.toast.show("Registry Created");
            navigate(`/modify/${createdId}`);
          } else {
            window.shopify.toast.show("Error Creating Registry", {
              isError: true,
            });
          }
        })
        .finally(() => {
          discard();
        });
    },
    [
      contactAddress,
      contactCity,
      contactCountry,
      contactEmail,
      contactPhone,
      contactPostal,
      contactProvince,
      coregistrantFirst,
      coregistrantLast,
      discard,
      fetchGr,
      name,
      registrantFirst,
      registrantLast,
      selectedDate,
      type,
      navigate,
    ],
  );

  useEffect(() => {
    if (!loaded) {
      fetchGr(`${REGISTRY_URL}?items=1`, {
        headers: { Accept: "application/json" },
      })
        .then((nextData) => {
          setRegistryTypes(nextData.TYPES.map((type) => type.NAME));
        })
        .catch((ex) => {
          console.error("Fetching Registries list Data", ex);
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }, [fetchGr, loaded]);

  return (
    <Page title="Create Registry" backAction={{ content: "Dashboard", url: "/dashboard" }}>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <TextField
            label="Registry Name"
            value={name}
            name="registry-name"
            onChange={setName}
            autoComplete="off"
            requiredIndicator
            error={name ? false : "Registry Name is required"}
          />
          <Select label="Registry Type" options={registryTypes} value={type} name="registry-type" onChange={setType} />
          <TextField
            label="Registrant First Name"
            value={registrantFirst}
            name="registry-registrant-first-name"
            onChange={setRegistrantFirst}
            autoComplete="off"
            requiredIndicator
            error={registrantFirst ? false : "Registrant First Name is required"}
          />
          <TextField
            label="Registrant Last Name"
            value={registrantLast}
            name="registry-registrant-last-name"
            onChange={setRegistrantLast}
            autoComplete="off"
            requiredIndicator
            error={registrantLast ? false : "Registrant Last Name is required"}
          />
          <TextField
            label="Coregistrant First Name"
            value={coregistrantFirst}
            name="registry-coregistrant-first-name"
            onChange={setCoregistrantFirst}
            autoComplete="off"
          />
          <TextField
            label="Coregistrant Last Name"
            value={coregistrantLast}
            name="registry-coregistrant-last-name"
            onChange={setCoregistrantLast}
            autoComplete="off"
          />
          <Popover
            active={visible}
            autofocusTarget="none"
            preferredAlignment="left"
            fullWidth
            preferInputActivator={false}
            preferredPosition="below"
            preventCloseOnChildOverlayClick
            onClose={handleOnClose}
            activator={
              <TextField
                role="combobox"
                label="Event Date"
                prefix={<Icon source={CalendarIcon} />}
                value={formattedValue}
                onFocus={() => setVisible(true)}
                autoComplete="off"
                requiredIndicator
                error={formattedValue ? false : "Event Date is required"}
              />
            }
          >
            <Card ref={datePickerRef}>
              <DatePicker
                month={monthDate}
                year={yearDate}
                onChange={handleDateSelection}
                onMonthChange={handleMonthChangeGR}
                selected={selectedDate}
                disableDatesBefore={yesterdayDate}
              />
            </Card>
          </Popover>
          <TextField
            label="Email"
            type="email"
            prefix={<Icon source={EmailIcon} />}
            value={contactEmail}
            name="registry-contact-email"
            onChange={setContactEmail}
            autoComplete="email"
            requiredIndicator
            error={contactEmail ? false : "Email is required"}
          />
          <TextField label="Address" value={contactAddress} name="registry-contact-address" onChange={setContactAddress} autoComplete="off" />
          <TextField label="City" value={contactCity} name="registry-contact-city" onChange={setContactCity} autoComplete="off" />
          <TextField label="Province" value={contactProvince} name="registry-contact-province" onChange={setContactProvince} autoComplete="off" />
          <TextField label="Country" value={contactCountry} name="registry-contact-country" onChange={setContactCountry} autoComplete="off" />
          <TextField label="Postal" value={contactPostal} name="registry-contact-postal" onChange={setContactPostal} autoComplete="off" />
          <TextField
            label="Phone"
            type="tel"
            prefix={<Icon source={PhoneIcon} />}
            value={contactPhone}
            name="registry-contact-phone"
            onChange={setContactPhone}
            autoComplete="off"
          />
          <InlineStack gap="200">
            <Button submit>Submit</Button>
            <Button onClick={discard} tone="critical">
              Discard
            </Button>
          </InlineStack>
        </FormLayout>
      </Form>
    </Page>
  );
}

export default () => (
  <LegacyMessageContext>
    <CreateList />
  </LegacyMessageContext>
);
